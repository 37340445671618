import React from "react";
import {
  EvenstHeroContainer,
  EventsHeroInner,
  HeroLeft,
  HeroRight,
} from "./styles/event-styles";
import { TextContainer } from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
  PreTitle,
} from "components/rhombus-UI/theme/typography";

import { StaticImage } from "gatsby-plugin-image";
import RhombusButton from "components/common/RhombusButton";

const EventsHeader = () => {
  return (
    <EvenstHeroContainer>
      <EventsHeroInner>
        <HeroLeft>
          <TextContainer>
            <PreTitle>Events</PreTitle>
            <FancyPageTitle>
              Connect In-Person with the Rhombus Team
            </FancyPageTitle>
            <MainParagraph>
              Get hands-on with demos of our industry-leading cloud security
              solutions. Discuss how to integrate them seamlessly with your
              existing tools using our open API. Our experts will also guide you
              through customizable strategies to fortify your organization's
              cloud defenses.
            </MainParagraph>
            <RhombusButton
              title="Explore Events"
              path="#events-feed"
              style={{ marginTop: "1.25rem" }}
            />
          </TextContainer>
        </HeroLeft>
        <HeroRight>
          <StaticImage
            src="./img/events-hero.png"
            alt="Rhombus In-Person Events"
            objectFit="contain"
            quality={100}
          />
        </HeroRight>
      </EventsHeroInner>
    </EvenstHeroContainer>
  );
};

export default EventsHeader;
