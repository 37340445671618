import { EventContainer } from "components/RCTP-Program/styles/rctp-styles";
import {
  DefaultFlexContainer,
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import { Title } from "components/rhombus-UI/theme/typography";
import { StaticQuery, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import EventCard from "./EventCard";
import { NotFoundContainer } from "components/integrations/marketplace/styles/MarketPlace-css";
import Illustration from "../integrations/marketplace/img/not-found.svg";
import { EventsPageContainer } from "./styles/event-styles";

export default function EventsFeed() {
  const renderContent = data => {
    const { edges: _events } = data.allMarkdownRemark;
    const [events, setEvents] = useState(_events);

    useEffect(() => {
      const currentDate = new Date(Date.now()).toISOString();
      const filteredEvents = _events.filter(
        item => currentDate < item.node.frontmatter.endDate
      );
      setEvents(filteredEvents);
    }, [_events]);

    return (
      <EventsPageContainer id="events-feed">
        <SectionInner style={{ flexDirection: "column" }}>
          <DefaultFlexContainer style={{ flexDirection: "column" }}>
            <Title>Events</Title>
          </DefaultFlexContainer>
          <EventContainer>
            {events.length > 0 ? (
              events.map(event => <EventCard eventData={event} />)
            ) : (
              <NotFoundContainer
                style={{
                  maxWidth: "100%",
                  justifyContent: "center",
                  display: "flex",
                  margin: "2.5rem 0",
                }}
              >
                <img src={Illustration} alt="not found" />
                <p>Sorry we couldn't find any events</p>
              </NotFoundContainer>
            )}
          </EventContainer>
        </SectionInner>
      </EventsPageContainer>
    );
  };
  const GET_EVENTS = graphql`
    query {
      allMarkdownRemark(
        limit: 1000
        sort: { fields: [frontmatter___startDate], order: ASC }
        filter: {
          frontmatter: { templateKey: { eq: "event" }, draft: { ne: true } }
        }
      ) {
        edges {
          node {
            id
            frontmatter {
              speakingSession
              title
              image
              eventType
              booth
              location
              link
              startDate
              endDate
            }
          }
        }
      }
    }
  `;

  return <StaticQuery query={GET_EVENTS} render={renderContent} />;
}
