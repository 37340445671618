import React from "react";

import GlobalLayout from "../../components/page/GlobalLayout";
import Helmet from "react-helmet";
import EventsHeader from "components/events/EventsHeader";
import EventsFeed from "components/events/EventsFeed";
import CTABanner from "components/common/footer-banners/CTABanner";

export default function Events() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Rhombus Events</title>
        <meta
          name="description"
          content="Learn about the next trade show/conference Rhombus will be at, and get complete knowledge and insights on enterprise video surveillance, cloud access control, alarms, and more!"
        />
      </Helmet>
      <EventsHeader />
      <EventsFeed />
      <CTABanner />
    </GlobalLayout>
  );
}
